import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import axios from "axios"
import FormData from "form-data"
import { useFormik } from "formik"
import Headline from "../components/headline"
import Map from "../components/googleMap"
import HeroBoxes from "../components/heroBoxes"

export default function Contact({ data }) {
  const [submitMsg, setSubmitMsg] = useState("Submit")

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      how: "",
      message: "",
    },
    validate,
    onSubmit: values => {
      submitForm(values)
    },
  })

  const submitForm = values => {
    const contactData = new FormData()
    contactData.append("firstName", values.firstName)
    contactData.append("lastName", values.lastName)
    contactData.append("email", values.email)
    contactData.append("how", values.how)
    contactData.append("message", values.message)
    let config = {
      method: "post",
      url:
        "https://wp.donutworry.com.au/wp-json/contact-form-7/v1/contact-forms/76/feedback",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: contactData,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setSubmitMsg("Sent")
      })
      .catch(function (error) {
        console.log(error)
        setSubmitMsg("Try Again")
      })
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <section className="contact mt-20">
        <div className="container">
          <Headline title="Contact Us" />

          <div className="formContainer">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <input
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  <span className="error">
                    {formik.errors.firstName && formik.touched.firstName
                      ? `* ${formik.errors.firstName}`
                      : null}
                  </span>
                </div>

                <div className="col-6">
                  <input
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  <span className="error">
                    {formik.errors.lastName && formik.touched.lastName
                      ? `* ${formik.errors.lastName}`
                      : null}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <span className="error">
                    {formik.errors.email && formik.touched.email
                      ? `* ${formik.errors.email}`
                      : null}
                  </span>
                </div>
                <div className="col-6">
                  <select
                    id="how"
                    name="how"
                    type="how"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.how}
                  >
                    <option defaultValue>How did you find us?</option>
                    <option value="Google">Google </option>
                    <option value="Social media">Social media</option>
                    <option value="Word of mouth">Word of mouth</option>
                  </select>
                  <span className="error">
                    {formik.errors.how && formik.touched.how
                      ? `* ${formik.errors.how}`
                      : null}
                  </span>
                </div>
              </div>
              <div className="row">
                <textarea
                  rows="5"
                  id="message"
                  name="message"
                  type="message"
                  placeholder="Message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
              </div>

              <button
                className={`btn ${submitMsg === "Sent" ? "success" : ""} ${
                  submitMsg === "Try Again" ? "fail" : ""
                }  `}
                type="submit"
              >
                {submitMsg}
              </button>
            </form>
          </div>
        </div>
      </section>
      <HeroBoxes />
      <section className="location mt-40 md:mt-80">
        <Headline title="Our Locations" />
        <Map />
      </section>
    </Layout>
  )
}

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = "Required"
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less"
  }

  if (!values.lastName) {
    errors.lastName = "Required"
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less"
  }

  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  return errors
}
