import React from "react"

const IconBox = props => {
  return (
    <div className="icon-box bg-white rounded-3xl px-2 py-8 text-center lg:-mb-24">
      <div className={`icon-box__icon ${props.addedClass}`}>{props.icon}</div>
      <p className="title text-lg xl:text-2xl  text-black my-3 xl:my-5">
        {props.title}
      </p>
      <p className="title text-lg xl:text-2xl  text-black my-3 xl:my-5">
        {props.anotherTitle}
      </p>

      <a href={props.link}>
        <p className="subtitle text-base xl:text-xl font-bold mb-0">
          {props.subtitle}
        </p>
      </a>
    </div>
  )
}

export default IconBox
