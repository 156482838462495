import React, { useEffect } from "react"
import icon from "../images/icon.png"

export default function Map() {
  function getWindowWidth() {
    const { innerWidth: width } = window
    return width
  }
  useEffect(() => {
    const initMap = () => {
      //locations
      const donutShop_1 = { lat: -31.98530813244964, lng: 115.90334110544865 } //victoria park
      const donutShop_2 = { lat: -31.947731859926375, lng: 115.85987964904949 } //northbridge
      const screenWidth = getWindowWidth()
      const zoom = screenWidth < 768 ? 12 : 13
      const center = { lat: -31.954212286235986, lng: 115.88795416411337 }

      const map = new window.google.maps.Map(document.getElementById("map"), {
        center,
        zoom,
      })

      //info window
      const infowindow_1 = new window.google.maps.InfoWindow({
        content: `<h3 class="m-0">Donut Worry</h1>
                  <p class="m-0">865 Albany Hwy, East Victoria Park WA 6101</p>
                  <a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" href="https://goo.gl/maps/z3H3TYhVh3J3RqCcA">Get Directions</a>`,
      })
      const infowindow_2 = new window.google.maps.InfoWindow({
        content: `<h3 class="m-0">Donut Worry</h1>
        <p class="m-0">261 William st Northbridge </p>
        <a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" href="https://goo.gl/maps/YyRv93MTGbaBKohi7">Get Directions</a>`,
      })
      //markers
      if (map) {
        // eslint-disable-next-line no-unused-vars
        const marker_1 = new window.google.maps.Marker({
          position: donutShop_1,
          map,
          icon: icon,
        })
        marker_1.addListener("click", () => {
          infowindow_1.open({
            anchor: marker_1,
            map,
            shouldFocus: false,
          })
        })
        const marker_2 = new window.google.maps.Marker({
          position: donutShop_2,
          map,
          icon: icon,
        })
        marker_2.addListener("click", () => {
          infowindow_2.open({
            anchor: marker_2,
            map,
            shouldFocus: false,
          })
        })
      }
    }

    initMap()
  }, [])

  return <div id="map" />
}
