import React from "react"
import { BsGeoAlt } from "react-icons/bs"
import { FaMap, FaEnvelope } from "react-icons/fa"
import IconBox from "./icon-box"
import IceCream from "../images/svgs/ice-cream-cone.svg"
import IceCreamCup from "../images/svgs/ice-cream-cup.svg"

const HeroBoxes = props => {
  return (
    <section className="heroBoxes">
      <div className="container -mb-24">
        <IceCream
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="iceCreamCone_heroBox invisible md:visible"
        />
        <IceCreamCup
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="iceCreamCup_heroBox invisible md:visible"
        />
        <div className="w-10/12 grid grid-cols-1 lg:grid-cols-3 lg:gap-x-10 gap-y-10 lg:gap-y-0 mx-auto pt-14 pb-14 lg:pb-0">
          <IconBox
            title="Greek Donuts - Loukoumades"
            anotherTitle="Gelato"
            subtitle="Our Menu"
            addedClass="addCircle"
            link="/menu"
            icon={<FaMap className="icon circle w-16 h-auto inline-block" />}
          />
          <IconBox
            title="East Victoria Park"
            anotherTitle="Northbridge "
            subtitle="Go to Locations"
            addedClass="addCircle"
            link="/locations"
            icon={<BsGeoAlt className="icon circle w-16 h-auto inline-block" />}
          />
          <IconBox
            title="info@donutworry.com.au "
            subtitle="Email us"
            addedClass="addCircle"
            link="mailto:info@donutworry.com.au"
            icon={
              <FaEnvelope className="icon circle w-16 h-auto inline-block" />
            }
          />
        </div>
      </div>
    </section>
  )
}

export default HeroBoxes
